import Parse from 'parse'
import axios from 'axios'
//Initialize Parse
Parse.initialize(
	'TOXl4sxaKQyhYfLtsf6d36dOqwbFpTwgSAIpME4u',
	'HiuH4yRGTBAbQbM7ELx9Fkji6EGYyUIfn9pT4lzR'
)
Parse.serverURL = 'https://parseapi.back4app.com'

const mailchimpAPIKey =
	'e1511c098d037521c95fc3fcd18b74bf3af2c8865423bf25766948f95229ebe6-1570914694'
const audienceID = '111ccf94f2'
const baseMailchimpURL = 'https://us14.api.mailchimp.com'

export async function addToMailingList(email, firstName, lastName) {
	//HOWTO check to see if contact already subscribed
	//Convert email to all lowercase
	//Convert to md5 hash
	//Make a get request to /3.0/lists/listID/members/md5Hash

	//HOWOTO Add a contact to a list
	//Send a post request to /3.0/lists/listID/members/
	//List body containts email_address, status: "subscribed", merge_fields: {firstname, lastname}
	axios
		.post(
			`${baseMailchimpURL}/3.0/lists/${audienceID}/members/`,
			{
				email_address: email,
				status: 'subscribed',
				merge_fields: {
					FNAME: firstName,
					LNAME: lastName
				}
			},
			{
				auth: {
					username: 'foo',
					password: mailchimpAPIKey
				}
			}
		)
		.then(res => {
			console.log(res)
		})
		.catch(err => {
			//Something went wrong creating a contact
			console.log(err)
		})
}

export async function addEntry(
	fullName,
	email,
	igHandle,
	aboutYou,
	fullBodyImage
) {
	//Set up Parse database instance
	const Entries = Parse.Object.extend('Entries')
	const entry = new Entries()

	//Create the event flyer
	let parseFile = new Parse.File('flyer.png', fullBodyImage)

	var returnVal

	//Get event information
	entry.set('fullName', fullName)
	entry.set('email', email)
	entry.set('igHandle', igHandle)
	entry.set('aboutYou', aboutYou)
	entry.set('fullBodyImage', parseFile)

	await entry.save().then(
		savedEntry => {
			console.log(`Saved event with id: ${savedEntry.id}`)
			returnVal = 200
		},
		err => {
			console.log(`Something went wrong, ${err.message}`)
			returnVal = err
		}
	)

	if (returnVal === 200) {
		return true
	} else {
		return returnVal.message
	}
}

//USERS
export async function CurrentUserAsync() {
	let currentUser = await Parse.User.currentAsync()

	if (currentUser == null) {
		return
	}

	currentUser = {
		id: currentUser.id,
		fullName: currentUser.get('fullName'),
		email: currentUser.get('email'),
		gender: currentUser.get('gender')
	}

	//Check for profile picture
	try {
		currentUser.profilePicture = currentUser.get('profilePicture').url()
	} catch {
		// console.log("No profile picture set")
	}

	return currentUser
}
export async function Register(
	fullName,
	email,
	profilePicture,
	gender,
	password
) {
	var user = new Parse.User()
	var val = undefined

	if (profilePicture) {
		//Create the body image
		let parseFile = new Parse.File('image.png', profilePicture)
		user.set('profilePicture', parseFile)
	}

	user.set('fullName', fullName)
	user.set('username', email)
	user.set('password', password)
	user.set('gender', gender)

	user.set('email', email)

	try {
		await user.signUp()
		val = user
		// Hooray! Let them use the app now.
	} catch (error) {
		// Show the error message somewhere and let the user try again.
		// alert(" " + error.message);
		val = error
	}

	return val
}

export async function LoginUser(email, password) {
	var val = undefined

	try {
		const user = await Parse.User.logIn(email, password)
		val = user
	} catch (err) {
		console.log('What the fuck dude')
		console.log(err)
		// alert(err.message);
	}
	return val
}

export async function sendPasswordReset(email) {
	var returnVal = undefined

	Parse.User.requestPasswordReset(email)
		.then(() => {
			// Password reset request was sent successfully
			returnVal = true
		})
		.catch(error => {
			// Show the error message somewhere
			alert('Error: ' + error.message)
		})

	return returnVal
}
